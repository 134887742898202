import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

/*
===============================================================================
  Webpack imports for css, img and fonts
===============================================================================
*/
// Stylesheets
import '../scss/app.scss';

// Fonts
import '../fonts/CircularStd-Bold.woff2';
import '../fonts/CircularStd-Book.woff2';

/*
===============================================================================
  Application imports
===============================================================================
*/

import { WINDOW, DOCUMENT, BODY } from './helpers/browser';

async function bootstrap() {
  if (env === 'staging' || env === 'prod') {
    Sentry.init({
      dsn:
        'https://e28398fbb4dc992eaa7d59c50d2afb17@o944966.ingest.us.sentry.io/4507297966915584',
      // Performance Monitoring
      tracesSampleRate: 0.1, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: env,
    });
  }

  if (Array.from(BODY.querySelectorAll('.js-header')).length) {
    const { default: Header } = await import(
      /* webpackChunkName: "modules-Header" */ './modules/Header'
    );
    Array.from(BODY.querySelectorAll('.js-header')).forEach(el => Header(el));
  }

  const communitySearchPocEl = BODY.querySelector('.js-community-search-poc');
  if (communitySearchPocEl) {
    const { default: CommunitySearchPoc } = await import(
      /* webpackChunkName: "modules-CommunitySearchPoc" */ './modules/CommunitySearchPoc'
    );

    CommunitySearchPoc(communitySearchPocEl);
  }

  if (Array.from(BODY.querySelectorAll('.js-header-secondary')).length) {
    const { default: HeaderSecondary } = await import(
      /* webpackChunkName: "modules-HeaderSecondary" */ './modules/HeaderSecondary'
    );
    Array.from(BODY.querySelectorAll('.js-header-secondary')).forEach(
      el => new HeaderSecondary(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-click-to-call')).length) {
    const { default: ClickToCall } = await import(
      /* webpackChunkName: "modules-ClickToCall" */ './modules/ClickToCall'
    );
    Array.from(BODY.querySelectorAll('.js-click-to-call')).forEach(el =>
      ClickToCall(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-testimonial-block')).length) {
    const { default: TestimonialBlock } = await import(
      /* webpackChunkName: "modules-TestimonialBlock" */ './modules/TestimonialBlock'
    );
    Array.from(BODY.querySelectorAll('.js-testimonial-block')).forEach(el =>
      TestimonialBlock(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-hero-gallery')).length) {
    const { default: HeroGallery } = await import(
      /* webpackChunkName: "modules-TestimonialBlock" */ './modules/HeroGallery'
    );
    Array.from(BODY.querySelectorAll('.js-hero-gallery')).forEach(el =>
      HeroGallery(el)
    );
  }

  // in-card gallery components
  if (Array.from(BODY.querySelectorAll('.js-gallery')).length) {
    const { default: Gallery } = await import(
      /* webpackChunkName: "modules-Gallery" */ './modules/Gallery'
    );
    Array.from(BODY.querySelectorAll('.js-gallery')).forEach(
      el => new Gallery(el)
    );
  }

  // non-homes gallery module block
  if (Array.from(BODY.querySelectorAll('.js-gallery-module')).length) {
    const { default: Gallery } = await import(
      /* webpackChunkName: "modules-Gallery" */ './modules/Gallery'
    );
    Array.from(BODY.querySelectorAll('.js-gallery-module')).forEach(
      el => new Gallery(el)
    );
  }

  // home details module block
  if (Array.from(BODY.querySelectorAll('.js-gallery-home-details')).length) {
    const { default: GalleryHomeDetails } = await import(
      /* webpackChunkName: "modules-GalleryHomeDetails" */ './modules/GalleryHomeDetails'
    );
    Array.from(BODY.querySelectorAll('.js-gallery-home-details')).forEach(
      el => new GalleryHomeDetails(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-video-gallery')).length) {
    const { default: VideoGallery } = await import(
      /* webpackChunkName: "modules-VideoGallery" */ './modules/VideoGallery'
    );
    Array.from(BODY.querySelectorAll('.js-video-gallery')).forEach(
      el => new VideoGallery(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-lightbox')).length) {
    const { default: Lightbox } = await import(
      /* webpackChunkName: "modules-Lightbox" */ './modules/Lightbox'
    );
    Array.from(BODY.querySelectorAll('.js-lightbox')).forEach(
      el => new Lightbox(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-carousel')).length) {
    const { default: Carousel } = await import(
      /* webpackChunkName: "modules-Carousel" */ './modules/Carousel'
    );
    Array.from(BODY.querySelectorAll('.js-carousel')).forEach(
      el => new Carousel(el)
    );
  }

  if (
    Array.from(BODY.querySelectorAll('.js-events-block-mobile-swiper')).length
  ) {
    const { default: EventsBlockMobileSwiper } = await import(
      /* webpackChunkName: "modules-EventsBlockMobileSwiper" */ './modules/EventsBlockMobileSwiper'
    );
    Array.from(
      BODY.querySelectorAll('.js-events-block-mobile-swiper')
    ).forEach(el => EventsBlockMobileSwiper(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-enquiry-form')).length) {
    const { default: EnquiryForm } = await import(
      /* webpackChunkName: "modules-EnquiryForm" */ './modules/EnquiryForm'
    );
    Array.from(BODY.querySelectorAll('.js-enquiry-form')).forEach(el =>
      EnquiryForm(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-enquiry-multi-step-form')).length) {
    const { default: EnquiryMultiStepForm } = await import(
      /* webpackChunkName: "modules-EnquiryMultiStepForm" */ './modules/EnquiryMultiStepForm'
    );
    Array.from(
      BODY.querySelectorAll('.js-enquiry-multi-step-form')
    ).forEach(el => EnquiryMultiStepForm(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-form-events')).length) {
    const { default: FormEvents } = await import(
      /* webpackChunkName: "modules-FormEvents" */ './modules/FormEvents'
    );
    Array.from(BODY.querySelectorAll('.js-form-events')).forEach(el =>
      FormEvents(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-form-modal')).length) {
    const { default: FormModal } = await import(
      /* webpackChunkName: "modules-FormModal" */ './modules/FormModal'
    );
    Array.from(BODY.querySelectorAll('.js-form-modal')).forEach(el =>
      FormModal(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-nearby-form')).length) {
    const { default: NearbyForm } = await import(
      /* webpackChunkName: "modules-NearbyForm" */ './modules/NearbyForm'
    );
    Array.from(BODY.querySelectorAll('.js-nearby-form')).forEach(el =>
      NearbyForm(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-filter')).length) {
    const { default: FilterCommunityAndHomes } = await import(
      /* webpackChunkName: "modules-FilterCommunityAndHomes" */ './modules/FilterCommunityAndHomes'
    );
    Array.from(BODY.querySelectorAll('.js-filter')).forEach(el =>
      FilterCommunityAndHomes(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-filter-community')).length) {
    const { default: FilterCommunity } = await import(
      /* webpackChunkName: "modules-FilterCommunity" */ './modules/FilterCommunity'
    );
    Array.from(BODY.querySelectorAll('.js-filter-community')).forEach(el =>
      FilterCommunity(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-filter-homes')).length) {
    const { default: FilterHomes } = await import(
      /* webpackChunkName: "modules-FilterHomes" */ './modules/FilterHomes'
    );
    Array.from(BODY.querySelectorAll('.js-filter-homes')).forEach(el =>
      FilterHomes(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-filter-blog')).length) {
    const { default: FilterBlog } = await import(
      /* webpackChunkName: "modules-FilterBlog" */ './modules/FilterBlog'
    );
    Array.from(BODY.querySelectorAll('.js-filter-blog')).forEach(el =>
      FilterBlog(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-filter-events')).length) {
    const { default: FilterEvents } = await import(
      /* webpackChunkName: "modules-FilterEvents" */ './modules/FilterEvents'
    );
    Array.from(BODY.querySelectorAll('.js-filter-events')).forEach(el =>
      FilterEvents(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-subscription-form')).length) {
    const { default: SubscriptionForm } = await import(
      /* webpackChunkName: "modules-SubscriptionForm" */ './modules/SubscriptionForm'
    );
    Array.from(BODY.querySelectorAll('.js-subscription-form')).forEach(el =>
      SubscriptionForm(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('a[href^="#"]')).length) {
    const { ScrollTo } = await import(
      /* webpackChunkName: "modules-ScrollTo" */ './modules/ScrollTo'
    );
    Array.from(BODY.querySelectorAll('a[href^="#"]')).forEach(el => {
      if (!el.classList.contains('c-header-secondary__nav-item')) {
        new ScrollTo(el);
      }
    });
  }

  if (Array.from(BODY.querySelectorAll('.js-book-tour-cta')).length) {
    const { default: BookATourCta } = await import(
      /* webpackChunkName: "modules-BookATourCta" */ './modules/BookATourCta'
    );
    Array.from(BODY.querySelectorAll('.js-book-tour-cta')).forEach(el =>
      BookATourCta(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-addToCalendar')).length) {
    const { default: AddToCalendar } = await import(
      /* webpackChunkName: "modules-AddToCalendar" */ './modules/AddToCalendar'
    );
    Array.from(BODY.querySelectorAll('.js-addToCalendar')).forEach(el =>
      AddToCalendar(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-addToCalendar-cta')).length) {
    const { default: AddToCalendarCta } = await import(
      /* webpackChunkName: "modules-AddToCalendarCta" */ './modules/AddToCalendarCta'
    );
    Array.from(BODY.querySelectorAll('.js-addToCalendar-cta')).forEach(el =>
      AddToCalendarCta(el)
    );
  }

  if (
    Array.from(BODY.querySelectorAll('.js-favourites-button-wrapper')).length
  ) {
    const { default: FavouriteButtonWrapper } = await import(
      /* webpackChunkName: "modules-FavouriteButtonWrapper" */ './modules/FavouriteButtonWrapper'
    );
    Array.from(
      BODY.querySelectorAll('.js-favourites-button-wrapper')
    ).forEach(el => FavouriteButtonWrapper(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-saved-homes')).length) {
    const { default: SavedHomes } = await import(
      /* webpackChunkName: "modules-SavedHomes" */ './modules/SavedHomes'
    );
    Array.from(BODY.querySelectorAll('.js-saved-homes')).forEach(el =>
      SavedHomes(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-saved-communities')).length) {
    const { default: SavedCommunities } = await import(
      /* webpackChunkName: "modules-SavedCommunities" */ './modules/SavedCommunities'
    );
    Array.from(BODY.querySelectorAll('.js-saved-communities')).forEach(el =>
      SavedCommunities(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-home-equity-calculator')).length) {
    const { default: HomeEquityCalculator } = await import(
      /* webpackChunkName: "modules-HomeEquityCalculator" */ './modules/HomeEquityCalculator'
    );
    Array.from(
      BODY.querySelectorAll('.js-home-equity-calculator')
    ).forEach(el => HomeEquityCalculator(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-hero-video')).length) {
    const { default: HeroVideo } = await import(
      /* webpackChunkName: "modules-HeroVideo" */ './modules/HeroVideo'
    );
    Array.from(BODY.querySelectorAll('.js-hero-video')).forEach(el =>
      HeroVideo(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-hero-modal-video')).length) {
    const { default: HeroModalVideo } = await import(
      /* webpackChunkName: "modules-HeroModalVideo" */ './modules/HeroModalVideo'
    );
    Array.from(BODY.querySelectorAll('.js-hero-modal-video')).forEach(el =>
      HeroModalVideo(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-search-input')).length) {
    const { default: SearchInput } = await import(
      /* webpackChunkName: "modules-SearchInput" */ './modules/SearchInput'
    );
    Array.from(BODY.querySelectorAll('.js-search-input')).forEach(el =>
      SearchInput(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-referral-form')).length) {
    const { default: ReferralForm } = await import(
      /* webpackChunkName: "modules-ReferralForm" */ './modules/ReferralForm'
    );
    Array.from(BODY.querySelectorAll('.js-referral-form')).forEach(el =>
      ReferralForm(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-homesuite-form')).length) {
    const { default: HomesuiteForm } = await import(
      /* webpackChunkName: "modules-HomesuiteForm" */ './modules/HomesuiteForm'
    );
    Array.from(BODY.querySelectorAll('.js-homesuite-form')).forEach(el =>
      HomesuiteForm(el)
    );
  }

  if (
    Array.from(BODY.querySelectorAll('.js-referral-form-block-testimonials'))
      .length
  ) {
    const { default: ReferralFormBlockTestimonials } = await import(
      /* webpackChunkName: "modules-ReferralFormBlockTestimonials" */ './modules/ReferralFormBlockTestimonials'
    );
    Array.from(
      BODY.querySelectorAll('.js-referral-form-block-testimonials')
    ).forEach(el => ReferralFormBlockTestimonials(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-eyntk')).length) {
    const { default: EverythingYouNeedToKnow } = await import(
      /* webpackChunkName: "modules-EverythingYouNeedToKnow" */ './modules/EverythingYouNeedToKnow'
    );
    Array.from(BODY.querySelectorAll('.js-eyntk')).forEach(el =>
      EverythingYouNeedToKnow(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-trustpilot-review-form')).length) {
    const { default: TrustpilotReviewForm } = await import(
      /* webpackChunkName: "modules-TrustpilotReviewForm" */ './modules/TrustpilotReviewForm'
    );
    Array.from(
      BODY.querySelectorAll('.js-trustpilot-review-form')
    ).forEach(el => TrustpilotReviewForm(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-brochure-download')).length) {
    const { default: BrochureDownload } = await import(
      /* webpackChunkName: "modules-BrochureDownload" */ './modules/BrochureDownload'
    );
    Array.from(BODY.querySelectorAll('.js-brochure-download')).forEach(el =>
      BrochureDownload(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-get-directions')).length) {
    const { default: GetDirections } = await import(
      /* webpackChunkName: "modules-GetDirections" */ './modules/GetDirections'
    );
    Array.from(BODY.querySelectorAll('.js-get-directions')).forEach(el =>
      GetDirections(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-reserve-form')).length) {
    const { default: ReserveForm } = await import(
      /* webpackChunkName: "modules-ReserveForm" */ './modules/ReserveForm'
    );
    Array.from(BODY.querySelectorAll('.js-reserve-form')).forEach(el =>
      ReserveForm(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-media-block-modal-video')).length) {
    const { default: MediaBlockModalVideo } = await import(
      /* webpackChunkName: "modules-MediaBlockModalVideo" */ './modules/MediaBlockModalVideo'
    );
    Array.from(
      BODY.querySelectorAll('.js-media-block-modal-video')
    ).forEach(el => MediaBlockModalVideo(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-read-more')).length) {
    const { default: ReadMore } = await import(
      /* webpackChunkName: "modules-ReadMoreWrapper" */ './modules/ReadMore'
    );
    Array.from(BODY.querySelectorAll('.js-read-more')).forEach(el =>
      ReadMore(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-sf-book-a-tour')).length) {
    const { default: SalesforceBookATourForm } = await import(
      /* webpackChunkName: "modules-SalesforceBookATourForm" */ './modules/SalesforceBookATourForm'
    );
    Array.from(BODY.querySelectorAll('.js-sf-book-a-tour')).forEach(el =>
      SalesforceBookATourForm(el)
    );
  }

  if (
    Array.from(BODY.querySelectorAll('.js-mapovis-map-home-designs')).length
  ) {
    const { default: MapovisMapHomeDesigns } = await import(
      /* webpackChunkName: "modules-MapovisMapHomeDesigns" */ './modules/MapovisMapHomeDesigns'
    );
    Array.from(
      BODY.querySelectorAll('.js-mapovis-map-home-designs')
    ).forEach(el => MapovisMapHomeDesigns(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-accordion')).length) {
    const { default: Accordion } = await import(
      /* webpackChunkName: "modules-Accordion" */ './modules/Accordion'
    );
    Array.from(BODY.querySelectorAll('.js-accordion')).forEach(el =>
      Accordion(el)
    );
  }
}

((window, document) => {
  if (document.readyState === 'complete' || document.readyState !== 'loading') {
    bootstrap();
  } else {
    document.addEventListener('DOMContentLoaded', bootstrap);
  }
})(WINDOW, DOCUMENT);
