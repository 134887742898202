/* globals document window */

export const HTML = document.getElementsByTagName('html')[0];
export const BODY = document.body || document.getElementsByTagName('body')[0];
export const WINDOW = window;
export const DOCUMENT = document;
export const MOBILE_MAX = 767;

export const lockScrolling = function lockScrolling(
  scrollTop = WINDOW.pageYOffset || BODY.scrollTop || 0
) {
  if (!BODY.classList.contains('scroll-disabled')) {
    BODY.style.top = `-${scrollTop}px`;
    BODY.classList.add('scroll-disabled');
  }
};

export const unlockScrolling = function unlockScrolling() {
  const lastScrollTop = Math.abs(parseInt(BODY.style.top, 10));
  BODY.style.top = null;
  BODY.classList.remove('scroll-disabled');
  WINDOW.scrollTo(0, lastScrollTop);
};

export const getDimensions = function getDimensions() {
  return {
    height: WINDOW.innerHeight,
    width: WINDOW.innerWidth,
  };
};

export default {
  lockScrolling,
  unlockScrolling,
  getDimensions,
  HTML,
  BODY,
  WINDOW,
  DOCUMENT,
  MOBILE_MAX,
};
